import './App.css';
import config from "./coreFIles/config";
import Home from './component/home';
import Products from './component/products';
import Login from './component/login';
import ForgetPassword from './component/ForgetPassword';
import Ordersuccess from './component/Ordersuccess';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

	function App() {
		return (
		<BrowserRouter>
			<div>
				<Routes>					
					 <Route path={`${config.baseUrl}`} element={<Products />} />
					 <Route path={`${config.baseUrl}/login`} element={<Login />} />
					 <Route path={`${config.baseUrl}/forget-password`} element={<ForgetPassword />} />
					 <Route path={`${config.baseUrl}/order-success/`} element={<Ordersuccess />} />
				</Routes>
			</div>
		</BrowserRouter>
		);
	}


export default App;
