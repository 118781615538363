import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../coreFIles/config'
import {  } from '../Action/action';
import Cookies from 'js-cookie'
const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));

const Footer = () => {
	const [loader, setloader]						= useState(0);
	
	useEffect(() => {	
			
	},[])
	function logout() {
        Cookies.remove('loginSuccessfwMember');
        Cookies.remove('token');
        Cookies.remove('authlogin');        
        setTimeout(() => {
            window.location.href = `${config.baseUrl}`
        });
    }
	
	return (
		<>
		<footer className="porter-footer">
			<div className="container">
				<div className="main-footer">
					<div className="footer-contant">
						<p>© Fastener Warehouse Australia. All Rights Reserved. Website by [CM]</p>
					</div>
					<div className="footer-logo">
						 <img src="images/porterlogo.png" />
					</div>
					<div className="payment">
						<a className="footer-links" rel="noopener noreferrer" target="_blank" href="https://fastenerwarehouse.com.au/privacy-policy/">Terms and Conditions</a>&nbsp;&nbsp;
						{ loginData?.email =='' || loginData?.email ==undefined  ?
							(<Link className="logoutbtn" to={`${config.baseUrl}login`}>
							<span><i className="fa-solid fa-right-to-bracket"></i> </span>
							  Login</Link>)
						:	
						( <Link title="Logout" className="logoutbtn" to="javascript:;" onClick={logout}>
							<span><i className="fa-solid fa-right-from-bracket"></i></span>
							 Logout</Link>
						)
					}
					</div>
					
				</div>
			</div>
		</footer>
		
		</>
	)
}

export default Footer;
